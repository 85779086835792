import {web3} from "@project-serum/anchor";
import {VersionedTransaction} from "@solana/web3.js";

export class JupiterClient {
	controller
	loading = false

	rpcClient

	constructor(rpcClient) {
		this.rpcClient = rpcClient
	}

	cancelQuote() {
		if (this.controller)
			this.controller.abort("cancelled quote call")
	}

	async quote(inMint: web3.PublicKey, outMint: web3.PublicKey, amount: number, slippage: number): Promise<any> {
		this.cancelQuote()
		this.loading = true
		this.controller = new AbortController()
		const resp = await fetch(`https://quote-api.jup.ag/v6/quote?inputMint=${inMint}&outputMint=${outMint}&amount=${amount}&slippageBps=${slippage}&platformFeeBps=25`, {signal: this.controller.signal}).catch()
		this.loading = false
		if (!resp || resp.status != 200)
			return

		return await resp.json()
	}

	async swap(payer: web3.PublicKey, quote: any, priorityFee: number) {
		const [feeAccount] = web3.PublicKey.findProgramAddressSync([Buffer.from("referral_ata"), new web3.PublicKey("4RNnWnJeyy6myqFW4anPDJtmhnZTdSMDo2HWjfBiDcLc").toBuffer(), new web3.PublicKey(quote.outputMint).toBuffer()], new web3.PublicKey("REFER4ZgmyYx9c6He5XfaTMiGfdLwRnkV4RPp9t9iF3"))
		const payload = {
			userPublicKey: payer,
			useSharedAccounts: false,
			quoteResponse: quote,
			wrapAndUnwrapSol: true,
			dynamicComputeUnitLimit: true,
			prioritizationFeeLamports: priorityFee,
		}

		const feeAccountInfo = await this.rpcClient.getAccountInfo(feeAccount)
		if (feeAccountInfo) {
			//@ts-ignore
			payload.feeAccount = feeAccount
		}


		const resp = await (await fetch(`https://quote-api.jup.ag/v6/swap`, {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(payload),
		})).json()

		const swapTransactionBuf = Buffer.from(resp.swapTransaction, 'base64');
		return VersionedTransaction.deserialize(swapTransactionBuf);
	}
}